<template>
  <div class="w-full h-full flex flex-col items-center justify-start">
    <figure class="w-2/4 md:w-1/4 my-12">
      <img class="w-full" alt="Farmnet logo" src="@/assets/logo.svg" />
    </figure>
    <Button
      v-if="showGetApp"
      label="Hent App"
      class="mt-3 mb-6 bg-green-dark flex"
      icon="fa-mobile-alt"
      @click="getApp()"
    />
    <div
      v-if="location.hostname !== 'app.farmhelp.dk'"
      class="mb-3 flex flex-col w-3/4 md:w-1/4 text-center"
    >
      <span class="text-yellow">
        <i class="fas fa-undo"></i>
      </span>
      <p class="text-white mb-3">
        Data nulstilles på demo siden hvert 15 min. Timeren i app'en fortæller
        dig hvornår det sker.
      </p>
      <p class="text-green font-semibold text-lg text-center w-full mb-3">
        Demo logins:
      </p>
      <p
        @click="loginDemo('demo@farmhelp.dk', 'demo1234')"
        class="text-green bg-green-darkest hover:bg-green-dark mb-3 rounded-md py-1 px-3 cursor-pointer shadow-md"
      >
        <span class=" text-white">Jens</span> <br />
        Stilling:
        <span class=" text-green">Ejer</span>
      </p>
      <p
        @click="loginDemo('demo1@farmhelp.dk', 'demo1234')"
        class="text-green bg-green-darkest hover:bg-green-dark mb-3 rounded-md py-1 px-3 cursor-pointer shadow-md"
      >
        <span class=" text-white">Anna</span> <br />
        Stilling:
        <span class=" text-green">Leder Timelønnet</span>
      </p>
      <p
        @click="loginDemo('demo2@farmhelp.dk', 'demo1234')"
        class="text-green bg-green-darkest hover:bg-green-dark mb-3 rounded-md py-1 px-3 cursor-pointer shadow-md"
      >
        <span class=" text-white">Niels</span> <br />
        Stilling:
        <span class=" text-green">Ansat Timelønnet</span>
      </p>
      <p
        @click="loginDemo('demo3@farmhelp.dk', 'demo1234')"
        class="text-green bg-green-darkest hover:bg-green-dark mb-3 rounded-md py-1 px-3 cursor-pointer shadow-md"
      >
        <span class=" text-white">Peter</span> <br />
        Stilling:
        <span class=" text-green">Ansat Fastløn 42 timer / uge</span>
      </p>
      <p
        @click="loginDemo('demo4@farmhelp.dk', 'demo1234')"
        class="text-green bg-green-darkest hover:bg-green-dark mb-3 rounded-md py-1 px-3 cursor-pointer shadow-md"
      >
        <span class=" text-white">Rasmus</span> <br />
        Stilling:
        <span class=" text-green">Ansat Fastløn 37 timer / uge</span>
      </p>
      <p
        @click="loginDemo('demo5@farmhelp.dk', 'demo1234')"
        class="text-green bg-green-darkest hover:bg-green-dark mb-3 rounded-md py-1 px-3 cursor-pointer shadow-md"
      >
        <span class=" text-white">Lisa</span> <br />
        Stilling:
        <span class=" text-green">Ansat Fastløn 37 timer / uge</span>
      </p>
    </div>
    <div
      class="w-full flex md:flex-col md:items-center justify-center flex-wrap"
    >
      <template v-if="location.hostname === 'app.farmhelp.dk'">
        <Input
          :error="
            errorCode === 'auth/user-not-found' ||
              errorCode === 'auth/invalid-email' ||
              errorCode === 'auth/user-disabled'
          "
          :errorMessage="
            errorCode === 'auth/user-not-found' ||
            errorCode === 'auth/invalid-email' ||
            errorCode === 'auth/user-disabled'
              ? errorMessage
              : ''
          "
          class="w-3/4 md:w-1/3 mb-6"
          labelWidth="w-4/12"
          inputWidth="w-8/12"
          icon="fa-user"
          type="text"
          placeholder="E-mail"
          v-model="email"
        />
        <Input
          @keyup.enter="signInSubmit"
          :error="errorCode === 'auth/wrong-password'"
          :errorMessage="
            errorCode === 'auth/wrong-password' ? errorMessage : ''
          "
          class="w-3/4 md:w-1/3 mb-3"
          labelWidth="w-4/12"
          inputWidth="w-8/12"
          icon="fa-lock"
          type="password"
          placeholder="Kodeord"
          v-model="password"
        />
        <span
          v-if="errorCode === 'auth/too-many-requests'"
          class="bg-white rounded-md text-red-500 w-3/4 md:w-1/3 font-semibold flex my-3"
        >
          <span class="flex justify-center items-center text-lg mx-4">
            <i class="fas fa-exclamation-circle"></i>
          </span>
          <p>
            {{ errorMessage }}
          </p>
        </span>
      </template>
    </div>
    <template v-if="location.hostname === 'app.farmhelp.dk'">
      <router-link
        class=" hover:text-yellow text-white mb-4 mt-4"
        :to="{ name: 'Create' }"
      >
        Opret din virksomhed
      </router-link>
      <router-link
        class=" hover:text-yellow text-white mb-4"
        :to="{ name: 'ResetPassword' }"
      >
        Anmod om ny kode
      </router-link>
      <Button
        label="Login"
        class="mt-6 w-3/4 md:w-1/3 bg-gradient-to-r from-yellow-dark to-yellow"
        @click="signInSubmit"
      />
    </template>
  </div>
</template>

<script>
import Button from "@/components/Button";
import Input from "@/components/Input";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { authentication } from "@/global/services/authentication";
import firebase from "firebase/app";
import "firebase/auth";
import { errorLogging } from "@/global/services/errorLogging";

export default {
  name: "page-login",
  components: {
    Button,
    Input
  },
  setup() {
    let deferredPrompt;

    const state = {
      email: ref(""),
      password: ref(""),
      errorCode: ref(null),
      errorMessage: ref(""),
      getAppIOS: ref(false),
      getAppAndroid: ref(false),
      location,
      showGetApp: ref(false)
    };

    const router = useRouter();

    const functions = {
      signInSubmit: () => {
        authentication
          .signIn(state.email.value, state.password.value)
          .then(() => {
            firebase
              .auth()
              .currentUser?.getIdTokenResult()
              .then(token => {
                if (token.claims.admin) {
                  router.push({ name: "ServiceLogin" });
                } else router.push({ name: "Dashboard" });
              })
              .catch(error => {
                errorLogging.setError(error.message);
              });
          })
          .catch(error => {
            errorLogging.setWarning(error.message);
            state.errorCode.value = error.code;
            state.errorMessage.value = error.message;
          });
      },

      loginDemo: (email, password) => {
        authentication
          .signIn(email, password)
          .then(token => {
            router.push({ name: "Dashboard" });
          })
          .catch(error => {
            errorLogging.setWarning(error.message);
            state.errorCode.value = error.code;
            state.errorMessage.value = error.message;
          });
      },

      getApp: () => {
        // hide our user interface that shows our A2HS button
        state.showGetApp.value = false;
        // Show the prompt
        deferredPrompt.prompt();
        // Wait for the user to respond to the prompt
        deferredPrompt.userChoice.then(choiceResult => {
          if (choiceResult.outcome === "accepted") {
            console.log("User accepted the A2HS prompt");
          } else {
            console.log("User dismissed the A2HS prompt");
          }
          deferredPrompt = null;
        });
      }
    };

    window.addEventListener("beforeinstallprompt", e => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
      // Update UI notify the user they can install the PWA
      state.showGetApp.value = true;
      // Optionally, send analytics event that PWA install promo was shown.
      console.log(`'beforeinstallprompt' event was fired.`);
    });

    return { ...state, ...functions };
  }
};
</script>

<style lang="scss" scoped>
::-webkit-input-placeholder {
  text-align: center;
}

:-moz-placeholder {
  text-align: center;
}
</style>
